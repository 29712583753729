import { Link, useLocation, useNavigate } from "react-router-dom"
import Nav from "../Report/Filters/Nav";
import axios from "axios";
import Swal from "sweetalert2";
import React, { useState } from "react";
import Switch from '@mui/material/Switch';

export default function Header({ pageTitle, imgSrc, toggle, setToggle, handleToggle, ...rest }) {
    const navigate = useNavigate();
    const location = useLocation();
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const brandId = user?.brandId;

    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    const handleGoBack = async () => {
        try {
            const response = await axios.post('/api/auth/goBack/login', {
                loggedAs: user.loggedAs,
                loggedAsId: user.loggedAsId
            });

            if (response.data.success) {
                localStorage.clear();
                localStorage.setItem('ag-authUser', JSON.stringify(response.data.data));

                if (response.data.data.loggedAs === "SuperAdmin") {
                    navigate('/brands');
                } else if (response.data.data.loggedAs === "BrandAdmin") {
                    navigate('/performance');
                } else {
                    navigate('/performance');
                }
            } else {
                Swal.fire(response.data.message, '', 'info');
            }
        } catch (error) {
            Swal.fire(error.response?.data?.message || 'An error occurred. Please try again.', '', 'info');
        }
    };


    return (
        <section className="header">
            <nav className="navbar navbar-expand-lg">
                <div className={`container ${(location.pathname === '/performance' || location.pathname === '/brandAttributes' || location.pathname === '/nps' || location.pathname === '/timeline') ? 'px-37' : ''}`}>
                    <Link className="navbar-brand d-flex py-0 order-1 order-md-1" >
                        <img src={`${process.env.REACT_APP_BASE_URL}assets/img/logo.png`} className="logo-img" />
                    </Link>

                    {brandId && brandId !== null && (location.pathname === '/performance' || location.pathname === '/brandAttributes' || location.pathname === '/nps' || location.pathname === '/timeline') ?
                        <div className="order-4 order-md-2">
                            <Nav />
                        </div>
                        : ""}
                    <div className="dropdown d-lg-none d-sm-block ms-auto me-2 order-2">
                        <div className="d-flex align-items-center dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <div className="user-details">
                                <img src={user && user.image ? user.image : "assets/img/images.jpg"} className="" />
                            </div>
                        </div>
                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow">
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <div className="user-name">
                                    <p>{user && user.firstName ? user.firstName : ''}</p>
                                    <span>{user && user.role ? user.role : ''}</span>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/profile">
                                    <i className="fa-solid fa-gear me-2"></i>
                                    <span className="">Setting</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/login" onClick={handleLogout}>
                                    <i className="fa-solid fa-power-off me-2"></i>
                                    <span className="">Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <button className="navbar-toggler ms-lg-auto me-sm-0 me-2 border-0 shadow-none order-3" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="text-dark fs25"><i className="fa-solid fa-bars"></i></span>
                    </button>
                    <div className="collapse navbar-collapse ss_nav order-3 order-md-3 align-items-center" id="navbarSupportedContent">
                        {/* <ul className="navbar-nav ss-nav mx-auto mb-2 mb-lg-0"> */}
                        <ul className="navbar-nav ss-nav ms-auto mb-2 mb-lg-0">
                            {user && user.role && user.role === 'SuperAdmin' ?
                                <>
                                    <li className="nav-item">
                                        <Link className={`nav-link hover-line ${location.pathname === '/brands' ? 'active' : ''}`} aria-current="page" to="/brands">Brands</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link hover-line ${location.pathname === '/data' ? 'active' : ''}`} to="/data">Data</Link>
                                    </li>
                                </>
                                : ""}

                            {/* {(
                                (user?.loggedAs === "SuperAdmin" && user?.role === "BrandAdmin") || 
                                (user?.loggedAs === "SuperAdmin" && user?.role === "User") || 
                                (user?.loggedAs === "BrandAdmin" && user?.role === "User")) ? 
                                <li className="nav-item" onClick={handleGoBack}>
                                    <Link className="nav-link hover-line">Go Back</Link>
                                </li> 
                            : ""} */}
                            
                                {/* {user.loggedAs === 'SuperAdmin' && (location.pathname === '/performance' || location.pathname === '/brandAttributes' || location.pathname === '/nps' || location.pathname === '/timeline') ?
                                    <div className="d-sm-none d-lg-block toggle-width">
                                        <div className="toggle-container">
                                            <Switch className="ss-switch" checked={toggle === 'All'} onChange={handleToggle} />
                                            <span>{toggle}</span>
                                        </div>
                                    </div>
                                : ''} */}
                            {brandId && brandId !== null ?
                                <>
                                    <li className="nav-item">
                                        <Link className={`nav-link hover-line ${(location.pathname === '/performance' || location.pathname === '/brandAttributes' || location.pathname === '/nps' || location.pathname === '/timeline') ? 'active' : ''}`} to="/performance">Report</Link>
                                    </li>
                                    {user && user.role && user.role !== 'User' ?
                                        <li className="nav-item">
                                            <Link className={`nav-link hover-line ${location.pathname === '/users' ? 'active' : ''}`} to="/users">Users</Link>
                                        </li>
                                        : ''}
                                </>
                                : ""}
                        </ul>
                    </div>
                    <div className="dropdown d-none d-lg-block order-md-4">
                        <div className="d-flex align-items-center dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <div className="user-details">
                                <img src={user && user.image ? user.image : "assets/img/images.jpg"} className="" />
                            </div>
                        </div>
                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow">
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <div className="user-name">
                                    {/* <p>{user && user.brand ? user.brand?.name : ''}</p> */}
                                    <p style={{fontSize:'12px'}}>{user && user.firstName ? user.firstName : ''} {user && user.lastName ? user.lastName : ''}</p>
                                    <span>{user && user.role ? user.role : ''} </span>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <Link className="dropdown-item userDropdown" to="/profile">
                                    <i className="fa-solid fa-gear me-2"></i>
                                    <span className="">Setting</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item userDropdown" to="/login" onClick={handleLogout}>
                                    <i className="fa-solid fa-power-off me-2"></i>
                                    <span className="">Logout</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    )
}

export default function AttributesFilter({selectedAttributes, setSelectedAttributes}) {
    // const handleAttributeChange = (event) => {
    //     const { id, checked } = event.target;
    //     if (id === 'btn-all') {
    //       // Toggle the 'All' checkbox
    //       const newSelection = !selectedAttributes.all;
    //       setSelectedAttributes((prevState) => {
    //         const updatedAttributes = {};
    //         for (let key in prevState) {
    //           updatedAttributes[key] = newSelection; // Set all attributes to match the 'All' checkbox state
    //         }
    //         return updatedAttributes;
    //       });
    //     } else {
    //       // Handle individual checkbox selections
    //       const attribute = event.target.value//event.target.nextSibling.textContent.trim();
    //       setSelectedAttributes((prevState) => ({
    //         ...prevState,
    //         [attribute]: checked,
    //         all: false, // Deselect 'All' if individual checkboxes are being changed
    //       }));
    //     }
    //   };


    const handleAttributeChange = (event) => {
      const { id, checked } = event.target;    
      if (id === 'btn-all') {
        setSelectedAttributes((prevState) => {
          const updatedAttributes = {};
          for (let key in prevState) {
            updatedAttributes[key] = false; 
          }
          return updatedAttributes;
        });
      } else {
        //  individual checkbox selections
        const attribute = event.target.value; 
        setSelectedAttributes((prevState) => ({
          ...prevState,
          [attribute]: checked,
          all: false,
        }));
      }
    };


    // const handleAttributeChange = (e) => {
    //   const { id, checked, value } = e.target;
    //   const attributeName = value;
    
    //   let newSelectedAttributes = { ...selectedAttributes };
    
    //   if (attributeName === "all") {
    //     // If "All" is checked or unchecked, update all options accordingly
    //     newSelectedAttributes = Object.keys(newSelectedAttributes).reduce((acc, key) => {
    //       acc[key] = checked;
    //       return acc;
    //     }, {});
    
    //   } else {
    //     newSelectedAttributes.all = false;
        
    //     if (attributeName === "Northland") {
    //       newSelectedAttributes["Northland"] = checked;
    //       newSelectedAttributes["Southland"] = !checked;
    //     } else if (attributeName === "Southland") {
    //       newSelectedAttributes["Southland"] = checked;
    //       newSelectedAttributes["Northland"] = !checked;
    //     }
    
    //     if (attributeName === "Dairy") {
    //       newSelectedAttributes.Dairy = checked;
    //       newSelectedAttributes["SheepAnBeef"] = !checked;
    //     } else if (attributeName === "SheepAnBeef") {
    //       newSelectedAttributes["SheepAnBeef"] = checked;
    //       newSelectedAttributes.Dairy = !checked;
    //     }
    
    //     if (attributeName === "upto250ha") {
    //       newSelectedAttributes["upto250ha"] = checked;
    //       newSelectedAttributes["over250ha"] = !checked;
    //     } else if (attributeName === "over250ha") {
    //       newSelectedAttributes["over250ha"] = checked;
    //       newSelectedAttributes["upto250ha"] = !checked;
    //     }
    
    //     if (attributeName === "under45") {
    //       newSelectedAttributes["under45"] = checked;
    //       newSelectedAttributes["over45"] = !checked;
    //     } else if (attributeName === "over45") {
    //       newSelectedAttributes["over45"] = checked;
    //       newSelectedAttributes["under45"] = !checked;
    //     }
    //   }
    
    //   setSelectedAttributes(newSelectedAttributes);
    // };

    return(
        <div className="col-md-12 col-10 mb-3">
          <div className="filterCheckBox">
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-all" 
                autoComplete="off" 
                checked={selectedAttributes.all} 
                value='all' 
                onChange={handleAttributeChange} 
              />
              <label className="btn" htmlFor="btn-all">All</label>
            </div>
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-North-Island" 
                autoComplete="off" 
                checked={selectedAttributes['Northland']} 
                value='Northland' 
                onChange={handleAttributeChange} 
              />
              <label className="btn" htmlFor="btn-North-Island">North Island</label>
            </div>
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-South-Island" 
                autoComplete="off" 
                checked={selectedAttributes['Southland']} 
                value='Southland'
                onChange={handleAttributeChange} 
              />
              <label className="btn me-md-0" htmlFor="btn-South-Island">South Island</label>
            </div>
            <div className="borderStart"></div>
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-Dairy" 
                autoComplete="off" 
                checked={selectedAttributes.Dairy}
                value='Dairy'
                onChange={handleAttributeChange} 
              />
              <label className="btn" htmlFor="btn-Dairy">Dairy</label>
            </div>
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-Sheeep-Beef" 
                autoComplete="off" 
                checked={selectedAttributes['SheepAnBeef']} 
                value='SheepAnBeef'
                onChange={handleAttributeChange} 
              />
              <label className="btn me-md-0" htmlFor="btn-Sheeep-Beef">Sheep & Beef</label>
            </div>
            <div className="borderStart"></div>
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-Up-250-ha" 
                autoComplete="off" 
                checked={selectedAttributes['upto250ha']} 
                value='upto250ha'
                onChange={handleAttributeChange} 
              />
              <label className="btn" htmlFor="btn-Up-250-ha">Up to 250 ha</label>
            </div>
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-Over-250-ha" 
                autoComplete="off" 
                checked={selectedAttributes['over250ha']} 
                value='over250ha'
                onChange={handleAttributeChange} 
              />
              <label className="btn me-md-0" htmlFor="btn-Over-250-ha">Over 250 ha</label>
            </div>
            <div className="borderStart"></div>
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-Under-45" 
                autoComplete="off" 
                checked={selectedAttributes['under45']} 
                value='under45'
                onChange={handleAttributeChange} 
              />
              <label className="btn" htmlFor="btn-Under-45">Under 45</label>
            </div>
            <div>
              <input 
                type="checkbox" 
                className="btn-check" 
                id="btn-Over-45" 
                autoComplete="off" 
                checked={selectedAttributes['over45']} 
                value='over45'
                onChange={handleAttributeChange} 
              />
              <label className="btn" htmlFor="btn-Over-45">Over 45</label>
            </div>
          </div>
        </div>
    )
}
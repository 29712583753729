import { useEffect, useState } from "react";

import Header from "../Header/Header";
import Loader from "../Loader/Loader";

import axios from "axios";
import Swal from 'sweetalert2';

import Nav from "./Filters/Nav";
import MonthYearFilters from "./Filters/MonthYearFilters";
import AttributesFilter from "./Filters/AttributesFilter";
import BrandMetricsChart from "./Charts/BrandMetricsChart";
import CompetitorFilters from "./Filters/CompetitorFilters";
import { useNavigate } from "react-router-dom";


export default function Report() {
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState('1');
    // const [year, setYear] = useState('');
    const [selectedAttributes, setSelectedAttributes] = useState({
        "all": false,
        "Northland": false,
        "Southland": false,
        "Dairy": false,
        "SheepAnBeef": false,
        "upto250ha": false,
        "over250ha": false,
        "under45": false,
        "over45": false
      });
    const [selectedCompetitor, setSelectedCompetitor] = useState('');
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const [toggle, setToggle] = useState('All');
    const handleToggle = (event) => { setToggle(event.target.checked ? 'All' : 'Approved')}

    useEffect(() => {
        if (user.role === 'SuperAdmin') {
            navigate('/');
        }
        if (month && selectedCompetitor) {
            getReport(month, selectedAttributes, selectedCompetitor);
        }
    }, [month, selectedAttributes, selectedCompetitor,toggle])

    const getReport = async (month, attribute, competitor) => {
        let condition = `?self=${user.brandId}`;

        month && (condition += `&month=${month}&role=${user.loggedAs}`);
        // year && (condition += `&year=${year}`);
        attribute && (condition += `&attribute=${JSON.stringify(attribute)}`);
        competitor && (condition += `&competitor=${competitor}`);
        toggle && (condition += `&reportType=${toggle}`);
        try {
            setLoading(true);
            const response = await axios.get(`/api/brandMatricReport${condition}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setData(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting prameters List: " + err);
        }
    };

    const handleMonthAndYearChange = (selectedValue) => {
        if (selectedValue) {
            getReport(selectedValue);
        }
    };

    const handleCompetitorChange = (event) => {
        setSelectedCompetitor(event.target.value);
    };

    return (
        <>
            <Loader loading={loading} />
            <Header toggle={toggle} setToggle={setToggle} handleToggle={handleToggle}/>
            <div className="" style={{ backgroundColor: '#dae8d8' }}>
                <section className="main-content ss-content">
                    <div className="container">
                        {/* <Nav /> */}
                        <div className="card bg-CardColor border-0 mb-3">
                            <div className="card-body px-4">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="d-flex align-items-center justify-content-between tabHeading">
                                            <h3 className="d-lg-block d-none"><span className="main_bank-span">{user?.brand?.name || ''}</span> Brand Performance</h3>
                                            <h3 className="d-lg-none d-md-block"><span className="main_bank-span">{user?.brand?.name || ''}</span><br/> Brand Performance</h3>
                                            <div className="selectLable d-none d-md-block">
                                                <MonthYearFilters
                                                    handleMonthAndYearChange={handleMonthAndYearChange}
                                                    month={month}
                                                    setMonth={setMonth}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <AttributesFilter 
                                        selectedAttributes={selectedAttributes}
                                        setSelectedAttributes={setSelectedAttributes}
                                    />
                                    <div className="col-6 mb-3 selectLable d-md-none d-sm-block">
                                        <MonthYearFilters
                                            handleMonthAndYearChange={handleMonthAndYearChange} 
                                            month={month}
                                            setMonth={setMonth}
                                        />
                                    </div>
                                    
                                    <CompetitorFilters 
                                        selectedCompetitor={selectedCompetitor} 
                                        setSelectedCompetitor={setSelectedCompetitor} 
                                        handleCompetitorChange={handleCompetitorChange} 
                                        setLoading={setLoading} 
                                    />
                                    
                                    <div className="col-lg-8 col-md-12 col-12 mb-md-3 mb-0 p-0">
                                        {data ? <BrandMetricsChart chartdata={data} /> : "Processing...!"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
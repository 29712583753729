import axios from "axios";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useState } from "react";

const validationSchema = yup.object({
    // email: yup
    //   .string('Enter your email')
    //   .email('Enter a valid email')
    //   .required('Email is required'),
    email: yup.string()
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Enter a valid email address'
        )
        .required('Email is required'),
    password: yup
      .string('Enter your password')
      .required('Password is required')
});

export default function Login(){
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => { showPassword ? setShowPassword(false) : setShowPassword(true); }

    const navigate = useNavigate();
    const initialValues = {
        email: '',
        password: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleSubmit = (values) => {
        console.log("values", values)
        axios.post('/api/auth/login', values).then((res) =>{
            if(res.data.success){
                localStorage.setItem('ag-authUser', JSON.stringify(res.data.data));
                if(res.data.data.role === 'SuperAdmin'){
                    navigate('/brands');
                } else{
                    navigate('/performance');
                }
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }
    return(
        <div className="bg-img">
            <section className="d-flex align-items-center justify-content-center flex-column min-vh-100 py-4">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-5 col-md-7">
                            <div className="card border-0 card_shadow">
                                <div className="card-body">
                                    <div className="login-panel pb-0">
                                        {/* <h1><span>Ag</span>Track</h1> */}
                                        <img src={`${process.env.REACT_APP_BASE_URL}assets/img/logo.png`} className="login-img" />
                                        <p>Please sign in to your account and start the adventure</p>
                                        <form autoComplete="" id="formAuthentication" className="mb-3"  onSubmit={formik.handleSubmit}>
                                            <div className="form-floating mb-3">
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    id="email"
                                                    placeholder="Enter your email" 
                                                    value={formik.values.email} 
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur}
                                                    autoComplete="off" 
                                                />
                                                <label htmlFor="email">Email address</label>
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="text-danger errorMSG">{formik.errors.email}</div>
                                                )}
                                            </div>
                                            <div className="form-floating position-relative  mb-3">
                                                <input 
                                                        type={showPassword ? "text" : "password"} 
                                                        className="form-control" 
                                                        id="password" 
                                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" 
                                                        autoComplete="off" 
                                                        value={formik.values.password} 
                                                        onChange={formik.handleChange} 
                                                        onBlur={formik.handleBlur}
                                                    />                                                    
                                                    <span className="cursor-pointer eye-icon" onClick={handleShowPassword}>
                                                        <i className={showPassword ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i>
                                                    </span>
                                                <label htmlFor="password">Password</label>
                                                {formik.touched.password && formik.errors.password && (
                                                    <div className="text-danger errorMSG">{formik.errors.password}</div>
                                                )}
                                            </div>
                                            <div className="mb-3 text-end">
                                                <Link to="/forgot-password"><span>Forgot Password?</span></Link>
                                            </div>
                                            <div className="d-grid">
                                                <button type="Submit" className="login-btn w-100">Login</button>
                                            </div>
                                        </form>
                                        <hr />
                                        <p className="text-sm ssBold">
                                            © {new Date().getFullYear()} <span className="fw-semibold" style={{color:'#F06B22'}}>Ag</span><span
                                                className="fw-semibold">Track</span> - All Rights Reserved.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default function ParametersFilter({parameters, selectedTimelineOption, setSelectedTimelineOption, ...rest}) {
    return (
        <div className="bankRadio tilelineFilter break-word-2 mb-sm-3 mb-md-0">
            {parameters.map((parameter) => (
                <div className="col" key={parameter.id}>
                    <input
                        type="radio"
                        className="btn-check"
                        name="timelineAttributes"
                        id={`timelineAttributes_${parameter.id}`}
                        autoComplete="off"
                        onChange={() => setSelectedTimelineOption(parameter.name)}
                        checked={selectedTimelineOption === parameter.name}
                    />
                    <label
                        className="btn"
                        htmlFor={`timelineAttributes_${parameter.id}`}
                        title={`Do you associate the following with this bank - '${parameter.name}.'`}
                    >
                        <span>{parameter.name}</span>
                    </label>
                </div>
            ))}
        </div>
    );
}
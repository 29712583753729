import { useEffect, useState } from "react";

import Header from "../Header/Header";
import Loader from "../Loader/Loader";

import axios from "axios";
import Swal from 'sweetalert2';

import Nav from "./Filters/Nav";
import TimelineFilters from "./Filters/TimelineFilters";
import AttributesFilter from "./Filters/AttributesFilter";
import TimelineChart from "./Charts/TimelineChart";
import CompetitorFilters from "./Filters/CompetitorFilters";
import MonthYearFilters from "./Filters/MonthYearFilters";
import ParametersFilter from "./Filters/ParametersFilter";

export default function Report() {
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);

    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState('3');
    const [year, setYear] = useState(new Date().getFullYear());
    // const [year, setYear] = useState('');   
    const [selectedAttributes, setSelectedAttributes] = useState({
        "all": false,
        "Northland": false,
        "Southland": false,
        "Dairy": false,
        "SheepAnBeef": false,
        "upto250ha": false,
        "over250ha": false,
        "under45": false,
        "over45": false
    });
    const [selectedCompetitor, setSelectedCompetitor] = useState('');
    const [selectedTimeLineFilter, setSelectedTimeLineFilter] = useState('Funnel');
    const [selectedTimelineOption, setSelectedTimelineOption] = useState('Unprompted Awareness');

    const [data, setData] = useState([]);
    const [parameters, setParameters] = useState([]);

    const [toggle, setToggle] = useState('All');
    const handleToggle = (event) => { setToggle(event.target.checked ? 'All' : 'Approved')}

    const getParametersList = async() => {
        try{
            const response = await axios.get(`api/parameter?brandId=${user.brandId}`);
            if(response.data.success) {
                setParameters(response.data.data); 
            } else {
                Swal.fire("Failed to fetch parameters.");
            }
        }catch(err) {
            console.log(err);
            Swal.fire("Error in getting parameters List: " + err);
        }
    }

    useEffect(() => {
        // if(user.brandId){
            getParametersList();
        // }
    }, [])

    useEffect(() => {

        if (month && selectedCompetitor && selectedTimelineOption) {
            getReport(month, selectedAttributes, selectedCompetitor, selectedTimelineOption);
        }
    }, [month, selectedAttributes, selectedCompetitor, selectedTimelineOption, toggle])

    const getReport = async (month, attribute, competitor, timelineFilterOption) => {

        let condition = `?self=${user.brandId}&role=${user.loggedAs}`;
        month && (condition += `&month=${month}`);
        // year && (condition += `&year=${year}`);
        attribute && (condition += `&attribute=${JSON.stringify(attribute)}`);
        competitor && (condition += `&competitor=${competitor}`);
        selectedTimeLineFilter && (condition += `&selectedTimeLineFilter=${selectedTimeLineFilter}`);
        timelineFilterOption && (condition += `&timelineFilterOption=${timelineFilterOption}`);
        toggle && (condition += `&reportType=${toggle}`);

        try {
            setLoading(true);
            const response = await axios.get(`/api/timelineReport${condition}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setData(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting prameters List: " + err);
        }
    };

    const handleMonthAndYearChange = (selectedValue) => {
        if (selectedValue) {
            getReport(selectedValue);
        }
    };

    const handleCompetitorChange = (event) => {
        setSelectedCompetitor(event.target.value);
    };

    const handleTimelineFilterChange = (value) => {
        setSelectedTimeLineFilter(value);

        if (value === 'Funnel') {
            setSelectedTimelineOption('Unprompted Awareness')
        } 
        else if (value === 'Attributes') {
            setSelectedTimelineOption(parameters?.[0]?.name)
        } 
        else if (value === 'NPS') {
            setSelectedTimelineOption('NPS');
        }
    };

    return (
        <>
            <Loader loading={loading} />
            <Header toggle={toggle} setToggle={setToggle} handleToggle={handleToggle}/>
            <div className="" style={{ backgroundColor: '#dae8d8' }}>
                <section className="main-content ss-content">
                    <div className="container">
                        {/* <Nav /> */}
                        <div className="card bg-CardColor border-0 mb-3">
                            <div className="card-body px-4">
                                <div className="row">
                                    <div className="col-md-12 mb-15">
                                        <div className="d-flex align-items-center justify-content-between tabHeading">
                                            <h3 className="d-lg-block d-none"><span className="main_bank-span">{user?.brand?.name || ''} </span>
                                                {selectedTimeLineFilter === 'Funnel'
                                                    ? 'Performance Over Time'
                                                    : selectedTimeLineFilter === 'Attributes'
                                                        ? 'Attributes Over Time'
                                                        : 'NPS Over Time'}
                                            </h3>
                                            <h3 className="d-lg-none d-md-block"><span className="main_bank-span">{user?.brand?.name || ''} </span><br/>
                                                {selectedTimeLineFilter === 'Funnel'
                                                    ? 'Performance Over Time'
                                                    : selectedTimeLineFilter === 'Attributes'
                                                        ? 'Attributes Over Time'
                                                        : 'NPS Over Time'}
                                            </h3>
                                            <div className="ms-auto d-none d-md-block">
                                                <div className="d-flex align-items-center">
                                                    <div className="radioBtn timelineFilter ss-content-2">
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFilter" id="timelinefunnel" autoComplete="off" onChange={() => handleTimelineFilterChange('Funnel')} checked={selectedTimeLineFilter === 'Funnel'} />
                                                            <label className="btn" htmlFor="timelinefunnel">Funnel</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFilter" id="timelineattributes" autoComplete="off" onChange={() => handleTimelineFilterChange('Attributes')} checked={selectedTimeLineFilter === 'Attributes'} />
                                                            <label className="btn" htmlFor="timelineattributes">Attributes</label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFilter" id="timelinenps" autoComplete="off" onChange={() => handleTimelineFilterChange('NPS')} checked={selectedTimeLineFilter === 'NPS'} />
                                                            <label className="btn" htmlFor="timelinenps">NPS</label>
                                                        </div>
                                                    </div>
                                                    <div className="selectLable ms-2 selectWidth">
                                                        <MonthYearFilters
                                                            handleMonthAndYearChange={handleMonthAndYearChange}
                                                            month={month}
                                                            setMonth={setMonth}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>                                    
                                    <AttributesFilter
                                        selectedAttributes={selectedAttributes}
                                        setSelectedAttributes={setSelectedAttributes}
                                    />
                                    <div className="col-6 mb-15 selectLable d-md-none d-sm-block">
                                        <MonthYearFilters
                                            handleMonthAndYearChange={handleMonthAndYearChange}
                                            month={month}
                                            setMonth={setMonth}
                                        />
                                    </div>
                                    <div className="col-12 d-md-none d-sm-block mb-15">
                                        <div className="radioBtn timelineFilter">
                                            <div className="col">
                                                <input type="radio" className="btn-check" name="timelineFilters" id="timelinefunnel" autoComplete="off" onChange={() => handleTimelineFilterChange('Funnel')} checked={selectedTimeLineFilter === 'Funnel'} />
                                                <label className="btn" htmlFor="timelinefunnel">Funnel</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" className="btn-check" name="timelineFilters" id="timelineattributes" autoComplete="off" onChange={() => handleTimelineFilterChange('Attributes')} checked={selectedTimeLineFilter === 'Attributes'} />
                                                <label className="btn" htmlFor="timelineattributes">Attributes</label>
                                            </div>
                                            <div className="col">
                                                <input type="radio" className="btn-check" name="timelineFilters" id="timelinenps" autoComplete="off" onChange={() => handleTimelineFilterChange('NPS')} checked={selectedTimeLineFilter === 'NPS'} />
                                                <label className="btn" htmlFor="timelinenps">NPS</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {selectedTimeLineFilter === 'Attributes' ? (
                                        <>
                                            <CompetitorFilters
                                            key={`attributes-${selectedTimeLineFilter}`}
                                            selectedCompetitor={selectedCompetitor}
                                            setSelectedCompetitor={setSelectedCompetitor}
                                            handleCompetitorChange={handleCompetitorChange}
                                            setLoading={setLoading}
                                            month={month}
                                            year={year}
                                            />
                                        </>
                                        ) : (
                                        <>
                                            <CompetitorFilters
                                            key={`other-${selectedTimeLineFilter}`}
                                            selectedCompetitor={selectedCompetitor}
                                            setSelectedCompetitor={setSelectedCompetitor}
                                            handleCompetitorChange={handleCompetitorChange}
                                            setLoading={setLoading}
                                            />
                                        </>
                                        )}
                                            
                                    <div className="col-lg-10 col-md-12 ss-pointer">
                                        <div className="row">
                                            <div className={selectedTimeLineFilter === 'Attributes' ? 'col-lg-3 col-md-3 col-12 ' : 'col-lg-2 col-md-2 col-12 '}>
                                                {selectedTimeLineFilter === 'Funnel' ?<>
                                                    <div className="bankRadio d-none d-lg-block tilelineFilter break-word mb-sm-3 mb-md-0">
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelUnprompted" id="timelineFunnelUnprompted" autoComplete="off" onChange={() => setSelectedTimelineOption('Unprompted Awareness')} checked={selectedTimelineOption === 'Unprompted Awareness'} />
                                                            <label className="btn" htmlFor="timelineFunnelUnprompted" title="When thinking about businesses that provide banking and financial services, which providers or brands first come to mind/are you aware of?"><span>Unprompted<br/> Awareness</span></label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelPrompted" id="timelineFunnelPrompted" autoComplete="off" onChange={() => setSelectedTimelineOption('Prompted awareness')} checked={selectedTimelineOption === 'Prompted awareness'} />
                                                            <label className="btn" htmlFor="timelineFunnelPrompted" title="From the list below, please select all the banking and financial services providers or brands you have heard of before today, even if you listed them earlier:"><span>Prompted<br/> Awareness</span></label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelConsideration" id="timelineFunnelConsideration" autoComplete="off" onChange={() => setSelectedTimelineOption('Consideration')} checked={selectedTimelineOption === 'Consideration'} />
                                                            <label className="btn" htmlFor="timelineFunnelConsideration" title="Which of the following banking or financial service providers would you consider using in the future?"><span>Consideration</span></label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelInvestigation" id="timelineFunnelInvestigation" autoComplete="off" onChange={() => setSelectedTimelineOption('Investigation')} checked={selectedTimelineOption === 'Investigation'} />
                                                            <label className="btn" htmlFor="timelineFunnelInvestigation" title="Before today, which of the following banking or financial service providers have you looked into or researched?"><span>Investigation</span></label>
                                                        </div>
                                                        {user?.brand?.hasAllAccess !== '0' ?
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelMainBankforBorrowing" id="timelineFunnelMainBankforBorrowing" autoComplete="off" onChange={() => setSelectedTimelineOption('Main bank for borrowing')} checked={selectedTimelineOption === 'Main bank for borrowing'} />
                                                            <label className="btn" htmlFor="timelineFunnelMainBankforBorrowing" title="And which banking and financial service provider do you use currently for the majority of your agribusiness borrowing?"><span>Main bank for<br/> borrowing</span></label>
                                                        </div>
                                                        :""}
                                                        
                                                        {user?.brand?.hasAllAccess !== '0' ?
                                                        <>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelPreference" id="timelineFunnelPreference" autoComplete="off" onChange={() => setSelectedTimelineOption('Preference')} checked={selectedTimelineOption === 'Preference'} />
                                                            <label className="btn" htmlFor="timelineFunnelPreference" title="Which of the following is your preferred bank?"><span>Preference</span></label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelAlternative" id="timelineFunnelAlternative" autoComplete="off" onChange={() => setSelectedTimelineOption('Preferred alternative')} checked={selectedTimelineOption === 'Preferred alternative'} />
                                                            <label className="btn" htmlFor="timelineFunnelAlternative" title="And if you had to change providers for your agribusiness borrowing, which provider is the most appealing to you?"><span>Preferred<br/> alternative</span></label>
                                                        </div>
                                                        </>
                                                        :""}
                                                    </div>
                                                    <div className="d-lg-none d-md-block bankRadio tilelineFilter break-word mb-sm-3 mb-md-0">
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelUnprompted-sm" id="timelineFunnelUnprompted-sm" autoComplete="off" onChange={() => setSelectedTimelineOption('Unprompted Awareness')} checked={selectedTimelineOption === 'Unprompted Awareness'} />
                                                            <label className="btn" htmlFor="timelineFunnelUnprompted-sm" title="When thinking about businesses that provide banking and financial services, which providers or brands first come to mind/are you aware of?"><span>Unprompted Awareness</span></label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelPrompted-sm" id="timelineFunnelPrompted-sm" autoComplete="off" onChange={() => setSelectedTimelineOption('Prompted awareness')} checked={selectedTimelineOption === 'Prompted awareness'} />
                                                            <label className="btn" htmlFor="timelineFunnelPrompted-sm" title="From the list below, please select all the banking and financial services providers or brands you have heard of before today, even if you listed them earlier:"><span>Prompted Awareness</span></label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelConsideration-sm" id="timelineFunnelConsideration-sm" autoComplete="off" onChange={() => setSelectedTimelineOption('Consideration')} checked={selectedTimelineOption === 'Consideration'} />
                                                            <label className="btn" htmlFor="timelineFunnelConsideration-sm" title="Which of the following banking or financial service providers would you consider using in the future?"><span>Consideration</span></label>
                                                        </div>
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelInvestigation-sm" id="timelineFunnelInvestigation-sm" autoComplete="off" onChange={() => setSelectedTimelineOption('Investigation')} checked={selectedTimelineOption === 'Investigation'} />
                                                            <label className="btn" htmlFor="timelineFunnelInvestigation-sm" title="Before today, which of the following banking or financial service providers have you looked into or researched?"><span>Investigation</span></label>
                                                        </div>
                                                        {user?.brand?.hasAllAccess !== '0' ?
                                                        <div className="col">
                                                            <input type="radio" className="btn-check" name="timelineFunnelMainBankforBorrowing-sm" id="timelineFunnelMainBankforBorrowing-sm" autoComplete="off" onChange={() => setSelectedTimelineOption('Main bank for borrowing')} checked={selectedTimelineOption === 'Main bank for borrowing'} />
                                                            <label className="btn" htmlFor="timelineFunnelMainBankforBorrowing-sm" title="And which banking and financial service provider do you use currently for the majority of your agribusiness borrowing?"><span>Main bank for borrowing</span></label>
                                                        </div>
                                                        :""}
                                                        {user?.brand?.hasAllAccess !== '0' ?
                                                            <>
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineFunnelPreference-sm" id="timelineFunnelPreference-sm" autoComplete="off" onChange={() => setSelectedTimelineOption('Preference')} checked={selectedTimelineOption === 'Preference'} />
                                                                    <label className="btn" htmlFor="timelineFunnelPreference-sm" title="Which of the following is your preferred bank?"><span>Preference</span></label>
                                                                </div>
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineFunnelAlternative-sm" id="timelineFunnelAlternative-sm" autoComplete="off" onChange={() => setSelectedTimelineOption('Preferred alternative')} checked={selectedTimelineOption === 'Preferred alternative'} />
                                                                    <label className="btn" htmlFor="timelineFunnelAlternative-sm" title="And if you had to change providers for your agribusiness borrowing, which provider is the most appealing to you?"><span>Preferred alternative</span></label>
                                                                </div>
                                                            </>
                                                        :""}
                                                    </div></>
                                                    : selectedTimeLineFilter === 'Attributes' ?
                                                        <ParametersFilter 
                                                            parameters={parameters}
                                                            selectedTimelineOption = {selectedTimelineOption}
                                                            setSelectedTimelineOption={setSelectedTimelineOption}
                                                        />
                                                        : selectedTimeLineFilter === 'NPS' ?
                                                            <div className="bankRadio tilelineFilter mb-sm-3 mb-md-0">
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineNPSnps" id="timelineNPSnps" autoComplete="off" onChange={() => setSelectedTimelineOption('NPS')} checked={selectedTimelineOption === 'NPS'} />
                                                                    <label className="btn npspadding-lr" htmlFor="timelineNPSnps"><span>NPS</span></label>
                                                                </div>
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineNPSPromoters" id="timelineNPSPromoters" autoComplete="off" onChange={() => setSelectedTimelineOption('Promoters')} checked={selectedTimelineOption === 'Promoters'} />
                                                                    <label className="btn npspadding-lr" htmlFor="timelineNPSPromoters"><span>Promoters</span></label>
                                                                </div>
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineNPSPassives" id="timelineNPSPassives" autoComplete="off" onChange={() => setSelectedTimelineOption('Passives')} checked={selectedTimelineOption === 'Passives'} />
                                                                    <label className="btn npspadding-lr" htmlFor="timelineNPSPassives"><span>Passives</span></label>
                                                                </div>
                                                                <div className="col">
                                                                    <input type="radio" className="btn-check" name="timelineNPSDetractors" id="timelineNPSDetractors" autoComplete="off" onChange={() => setSelectedTimelineOption('Detractors')} checked={selectedTimelineOption === 'Detractors'} />
                                                                    <label className="btn npspadding-lr" htmlFor="timelineNPSDetractors"><span>Detractors</span></label>
                                                                </div>
                                                            </div>
                                                            : ''}
                                            </div>
                                            <div className="col-lg-9 col-md-9 col-12 mb-md-3 mb-0">
                                                <TimelineChart chartData={data} selectedTimelineOption={selectedTimeLineFilter ? selectedTimelineOption : ''}  selectedFilter={selectedTimeLineFilter}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Autocomplete, TextField } from "@mui/material";
import { useRef } from "react";

const validationSchema = yup.object({
    brandId: yup
        .string()
        .required('Brand is required')
        .test('check-brand-data', 'Data already exists for this brand', async function (value) {
            const { month, year } = this.parent;
            if (value && month && year) {
                const isValid = await handleCheckIsUploadedDataAlreadyExist({ brandId: value, month, year });
                return isValid; 
            }
            return true; 
        }),

    month: yup
        .string()
        .required('Month is required')
        .test('check-month-data', 'Data already exists for this month', async function (value) {
            const { brandId, year } = this.parent;
            if (value && brandId && year) {
                const isValid = await handleCheckIsUploadedDataAlreadyExist({ brandId, month: value, year });
                return isValid;
            }
            return true;
        }),

    year: yup
        .number()
        .required('Year is required')
        .test('check-year-data', 'Data already exists for this year', async function (value) {
            const { brandId, month } = this.parent;
            if (value && brandId && month) {
                const isValid = await handleCheckIsUploadedDataAlreadyExist({ brandId, month, year: value });
                return isValid;
            }
            return true;
        }),

    survey_data: yup.mixed().required('Survey Data file is required'),
});

const handleCheckIsUploadedDataAlreadyExist = async ({ brandId, month, year}) => {
    try {
        const response = await axios.post("/api/checkIsUploadAlreadyExist", {
            brandId,
            month,
            year });
        return !response.data.dataExists;
    } catch (error) {
        console.error("Error checking data existence:", error);
        return true;
    }
};

export const Add = ({ show, setShow, handleClose, loading, setLoading, getList, showMapping, setShowMapping, setUploadBrandId, setUploadEditId, brands, ...rest}) => {

    const months = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' },
    ];
    const fileInputRef = useRef(null);

    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i)
        .map(year => ({ value: year, label: year }));

    const initialValues = {
        brandId: null,
        // date: '',
        month: '',
        year: '',
        survey_data: null,
    }
    

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values).then(() => {
                resetForm();
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            });
          },
    });
    
    const handleSubmit = async (values)=>{
        setLoading(true);

        const formData = new FormData();
        formData.append('brandId', values.brandId);
        // formData.append('date', values.date);
        formData.append('month', values.month);
        formData.append('year', values.year);

        formData.append('survey_data', values.survey_data);
        console.log("formData===>",formData)
        // return
        try{
            let response = await axios.post(`/api/uploadSurveyData`, formData);
            setLoading(false);
            if(response.data.success){
                console.log("response.data:=======",response.data)
                setShow(false);
                Swal.fire(response.data.message, '', 'success').then(result => {
                    setUploadBrandId(values.brandId);
                    setUploadEditId(response.data.data.id);
                    setShowMapping(true);
                    if(!result.isConfirmed){
                        getList();
                    }
                })
            }
            else {
                setShow(false);
                Swal.fire({
                    icon: 'info',
                    text: response.data.message || 'An error occurred while fetching data!',
                });
            }
        }catch(error) {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                text: error.message || error || 'Failed to connect to the server. Please try again later.',
            });
        };
           
    }
     
    const handleBrandChange = (newValue) => {
        if (newValue) {
            formik.setFieldValue('brandId', newValue.id)
        } else {
            formik.setFieldValue('brandId', null)
        }
    }
    
    const handleCloseModal = () => {
        formik.resetForm(); // Reset formik form when modal is closed
        handleClose(); // Close the modal
    };

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <form autoComplete="off" onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <Modal.Header>
                    <h1 className="modal-title" id="addUploadLabel">Upload Survey</h1>
                    <a className="ss-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}><i className="fa-solid fa-xmark"></i></a>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="brandName" className="form-label">Brand Name</label>
                                <Autocomplete
                                    id="brand-select"
                                    name="brandId"
                                    className="autoComplete-select font-visby-regular"
                                    size="small"
                                    style={{ backgroundColor: 'transparent', border: '0' }}
                                    options={brands}
                                    getOptionLabel={(option) => option.name}
                                    value={brands.find(brand => brand.id === parseInt(formik.values.brandId)) || null}
                                    onChange={(event, newValue) => handleBrandChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params} 
                                            className="form-control autoComplete-select font-visby-regular" 
                                            placeholder="Select Brand" 
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    componentsProps={{
                                        paper: {
                                            sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                        },
                                        popper: {
                                            sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                        },
                                    }}
                                />
                                {formik.touched.brandId && formik.errors.brandId && (
                                    <div className="text-danger errorMSG">{formik.errors.brandId}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12"> 
                            <div className="mb-3 flex-column">
                                <label htmlFor="month" className="form-label">Select Month</label>
                                <select
                                    id="month"
                                    className="form-control form-select"
                                    name="month"
                                    value={formik.values.month}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{ fontFamily: 'VisbyCF-Medium', letterSpacing: '1px' }}
                                >
                                    <option value="">Select</option>
                                    {months.map((month) => (
                                        <option key={month.value} value={month.value} style={{ fontFamily: 'VisbyCF-Medium', letterSpacing: '4px' }}>
                                            {month.label}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.month && formik.errors.month && (
                                    <div className="text-danger errorMSG">{formik.errors.month}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="year" className="form-label">Select Year</label>
                                <select
                                    id="year"
                                    className="form-control"
                                    name="year"
                                    value={formik.values.year}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="">Select</option>
                                    {years.map((year) => (
                                        <option key={year.value} value={year.value}>
                                            {year.label}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.year && formik.errors.year && (
                                    <div className="text-danger errorMSG">{formik.errors.year}</div>
                                )}
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="month-year" className="form-label">Select Date</label>
                                <input type="date" id="month-year" className="form-control" name="date"
                                    value={formik.values.date}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.date && formik.errors.date && (
                                    <div className="text-danger">{formik.errors.date}</div>
                                )}
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="">
                                <label htmlFor="formFile" className="form-label">Select File</label>
                                <input 
                                    ref={fileInputRef}
                                    type="file" 
                                    id="formFile"
                                    accept={".xlsx, .xls"} 
                                    name= 'survey_data'
                                    placeholder="Please select the survey data"
                                    className={formik.touched.survey_data && formik.errors.survey_data ?'account-file-input is-invalid form-control':'form-control account-file-input'}
                                    onChange={(e) => {
                                        formik.setFieldValue('survey_data', e.currentTarget.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.survey_data && formik.errors.survey_data ? (
                                    <div className="text-danger errorMSG">{formik.errors.survey_data}</div>
                                ) : null }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="reset" className="add-btn" onClick={handleCloseModal}>Close</button>       
                    <button type="submit" className="save-btn">Save and Complete Mapping</button>    
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};
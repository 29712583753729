import * as yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Swal from 'sweetalert2'
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';


const validationSchema = yup.object({
    brandId: yup.string().required('Brand is required'),
    // date: yup.string().required('Date is required'),
    month: yup.string().required('Month is required'),
    year: yup.number().required('Year is required'),
});

export const Edit = ({ show, setShow, handleClose, loading, setLoading, getList, setShowMapping, setShowEditMapping, editId, setUploadBrandId, brands, ...rest}) => {
    const [status, setStatus] = useState('');
    const months = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' },
    ];
    
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i)
        .map(year => ({ value: year, label: year }));

    const [filename, setFilename] = useState('');

    const initialValues = {
        id: editId,
        brandId: null,
        // date: '',
        month: '',
        year: '',
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });    
    const handleSubmit = async (values)=>{
        setLoading(true);

        const formData = new FormData();
        formData.append('id', values.id);
        formData.append('brandId', values.brandId);
        // formData.append('date', values.date);        
        formData.append('month', values.month);
        formData.append('year', values.year);
        // formData.append('survey_data', values.survey_data);
        
        try{
            let response = await axios.put(`/api/uploads/update`, formData);
            setLoading(false);
            if(response.data.success){
                setShow(false);
                Swal.fire(response.data.message, '', 'success').then(result => {
                    setUploadBrandId(values.brandId)
                    if(status === 'In-Complete'){
                        setShowMapping(true);
                    } else{
                        setShowEditMapping(true)
                    }
                    getList();
                })
            }
            else {
                setShow(false); 
                Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred while fetching data!',
                });
            }
        }catch(error) {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                text: error.message || error || 'Failed to connect to the server. Please try again later.',
            });
        };
           
    }    
    const handleCloseModal = () => {
        formik.resetForm(); // Reset formik form when modal is closed
        handleClose(); // Close the modal
    };

    useEffect(() => {
        if(editId > 0){
            axios.get(`api/upload?id=${editId}`).then(res => {
                if(res.data.success){
                    let date = new Date(res.data.data.date);
                    date = date.toISOString().split('T')[0];

                    formik.setValues({
                        id: res.data.data.id,
                        brandId: res.data.data.brandId || null,
                        date: date,
                        month: res.data.data.month || '',
                        year: res.data.data.year || '',
                    });
                    setFilename(res.data.data.filename);
                    setStatus(res.data.data.status);
                    }
            })
        }
        else{
            formik.resetForm();
            setFilename('');
        }
    },[show]);

    
    const handleBrandChange = (newValue) => {
        if (newValue) {
            formik.setFieldValue('brandId', newValue.id)
        } else {
            formik.setFieldValue('brandId', null)
        }
    }

    return (
        <Modal show={show} onHide={handleCloseModal}>
            <form autoComplete="off" onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <Modal.Header>
                    <h1 className="modal-title" id="addUploadLabel">Edit Upload Details</h1>
                    <a className="ss-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}><i className="fa-solid fa-xmark"></i></a>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="brandName" className="form-label">Brand Name</label>
                                {/* <Autocomplete
                                    disabled={true}
                                    id="brand-select"
                                    name="brandId"
                                    className="autoComplete-select"
                                    size="small"
                                    style={{ backgroundColor: 'transparent', border: '0' }}
                                    options={brands}
                                    getOptionLabel={(option) => option.name}
                                    value={brands.find(brand => brand.id === parseInt(formik.values.brandId)) || null}
                                    onChange={(event, newValue) => handleBrandChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params} 
                                            className="form-control autoComplete-select" 
                                            placeholder="Select Brand" 
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    componentsProps={{
                                        paper: {
                                            sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                        },
                                        popper: {
                                            sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                        },
                                    }}
                                />
                                {formik.touched.brandId && formik.errors.brandId && (
                                    <div className="text-danger errorMSG">{formik.errors.brandId}</div>
                                )} */}
                                <select
                                    disabled
                                    id="brand-select"
                                    name="brandId"
                                    className="form-control form-select"
                                    size="small"
                                    value={formik.values.brandId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="">Select Brand</option>
                                    {brands.map((brand) => (
                                        <option key={brand.id} value={brand.id}>
                                            {brand.name}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.brandId && formik.errors.brandId && (
                                    <div className="text-danger errorMSG">{formik.errors.brandId}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="month" className="form-label">Select Month</label>
                                <select
                                    disabled
                                    id="month"
                                    className="form-control form-select"
                                    name="month"
                                    value={formik.values.month}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="">Select</option>
                                    {months.map((month) => (
                                        <option key={month.value} value={month.value}>
                                            {month.label}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.month && formik.errors.month && (
                                    <div className="text-danger errorMSG">{formik.errors.month}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="year" className="form-label">Select Year</label>
                                <select
                                    disabled
                                    id="year"
                                    className="form-control form-select"
                                    name="year"
                                    value={formik.values.year}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="">Select</option>
                                    {years.map((year) => (
                                        <option key={year.value} value={year.value}>
                                            {year.label}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.year && formik.errors.year && (
                                    <div className="text-danger errorMSG">{formik.errors.year}</div>
                                )}
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="month-year" className="form-label">Select Date</label>
                                <input type="date" id="month-year" className="form-control" name="date"
                                    value={formik.values.date}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.date && formik.errors.date && (
                                    <div className="text-danger errorMSG">{formik.errors.date}</div>
                                )}
                            </div>
                        </div> */}
                        {filename ? 
                        <div className="col-md-12">
                            <div className="mb-3 flex-column">
                                <label htmlFor="year" className="form-label">Uploaded File:</label><br/>
                                <a className='breakWord' href={`${filename}`} download >{filename}</a>
                                {/* <Link to={filename} download>{filename}</Link> */}
                            </div>
                        </div>
                        :""}                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="reset" className="add-btn" onClick={handleCloseModal}>Close</button>       
                    <button type="submit" className="save-btn">Update and Complete Mapping</button>    
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Edit;
Edit.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};
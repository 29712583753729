import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { DataTableComp } from "../Common/Datatable";
import { Tooltip } from '@mui/material';
import axios from "axios";
import Swal from "sweetalert2";
import Edit from "./Edit";
import Mapping from "./Mapping";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import UploadExcel from './UploadExcel';
import EditMapping from "./EditMapping";
import Chip from '@mui/material/Chip';
import moment from 'moment-timezone';
import {useNavigate } from "react-router-dom";

export default function List(){
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [showExcelForm, setShowExcelForm] = useState(false);
    const handleCloseExcel = () => setShowExcelForm(false);
    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const [showMapping, setShowMapping] = useState(false);    
    const handleCloseMapping = () => setShowMapping(false);
    const [showEditMapping, setShowEditMapping] = useState(false);    
    const handleCloseEditMapping = () => setShowEditMapping(false);    
    const [loading, setLoading] = useState(false);    
    const [uploads, setUploads] = useState([]);
    const [brands, setBrands] = useState([]);
    const [brandId, setBrandId] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);    
    const [uploadEditId, setUploadEditId] = useState(null);
    const [uploadBrandId, setUploadBrandId] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    
    const navigate = useNavigate();
    
    const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i)

    const handleUpload = () => {
        setShowExcelForm(true);
    }

    const handleEdit = (uploadId) => {
        setShowEdit(true);
        setUploadEditId(uploadId)
    }

    useEffect(() => {
        if(user.role !== 'SuperAdmin') {
            navigate('/');
        }
        getList();
        getBrandList();
    }, []);

    const getBrandList = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/brands?status=1`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setBrands(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting brands List: " + err);
        }
    };

    const getList = async (filters='') => {
        filters = filters !== '' ? '?'+filters : filters;
        try {
            setLoading(true);
            const response = await axios.get(`/api/uploads${filters}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setUploads(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting uploads List: " + err);
        }
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            text: 'All related data will be deleted and it cannot be retrieved again!',
            // text2: 'Once retrived.',
            showCancelButton: true,
            confirmButtonColor: '#087f43',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/uploads/delete?id=${id}`).then((res) =>{
                    if(res.data.success){
                        Swal.fire('Deleted!', '', 'success').then(() => {
                            getList();
                        });
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    function formatTime(dateTimeStr) {
        const dateObj = new Date(dateTimeStr);
    
        // Extract hours and minutes
        let hours = dateObj.getUTCHours();
        const minutes = dateObj.getUTCMinutes();
    
        // Determine AM or PM suffix
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        // Convert hours to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
    
        // Format minutes with leading zero if needed
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    
        // Combine into the desired format
        const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;
    
        return formattedTime;
    }

    const handleSort = (column, sortDirection) => {
        // console.log(sortDirection)
        setSortDirection(sortDirection)
    };

    const handleBrandChange = (newValue) => {
        if (newValue) {
            setBrandId(newValue.id);
        } else {
            setBrandId(null);
        }
    }

    const handleMonthChange = (newValue) => {
        if (newValue) {
            setMonth(newValue);
        } else {
            setMonth(null);
        }
    }

    const handleYearChange = (newValue) => {
        if (newValue) {
            setYear(newValue);
        } else {
            setYear(null);
        }
    }

    const handleFilter = () => {
        let condition = '';
        if(brandId !== null){
            condition += ( condition !== '' ? `&brandId=${brandId}` : `brandId=${brandId}`);
        }        
        if(month !== null){
            condition += ( condition !== '' ? `&month=${month}` : `month=${month}`);
        }      
        if(year !== null){
            condition += ( condition !== '' ? `&year=${year}` : `year=${year}`);
        }
        if(condition !== ''){
            getList(condition);
        }
        else{
            getList();
        }
    }

    const handleClearFilter = () => {
        setBrandId(null);
        setMonth(null);
        setYear(null);
        getList()
    }

    const handleComplete = async (uploadId, brandId) => {               
        setUploadEditId(uploadId)
        setUploadBrandId(brandId)
        setShowMapping(true)
    }

    const handleApprove = async (uploadId) => {        
        setLoading(true);
        try {
            await axios.put(`api/uploads/updateStatus?id=${uploadId}`, { status:'Approved' });
            setLoading(false);
            getList();
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
            Swal.fire(error.message, '', 'error');
        }
    }

    const columns = [
        {
            name: 'S No.',
            selector: row => row.id,
            cell: (row, index) => sortDirection ==='desc' ? (uploads.length) - index : index + 1 ,
            sortable: true,
            width:'7%'
        },
        {
            name: 'Brand Name',
            selector: row => row?.brand?.name,
            sortable: true,
            width:'18%'
        },
        {
            name: 'Month',
            selector: row => row.month ? row.month : '-',
            sortable: true,
            width:'10%'
        },
        {
            name: 'Year',
            selector: row => row.year > 0 ? row.year : '-',
            sortable: true,
            width:'8%'
        },
        {
            name: 'Upload Date',
            // selector: row => new Date(row.createdAt).toISOString().split('T')[0],
            selector: row => moment(row.createdAt).tz(timeZone).format("YYYY-MM-DD"),
            sortable: true,
            width:'12%'
        },
        {
            name: 'Upload Time',
            selector: row => moment(row.createdAt).tz("Asia/Kolkata").format("hh:mm A"),
            sortable: true,
            width:'12%'
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width:'12%',
            cell: row => (
                <div className="d-flex align-items-center">
                    {
                    row.notes !== null
                        ? <div className={"text-danger"} style={{fontWeight: 'bold'}}>Errored</div>
                        : <div className={ row.status === "Approved" ? "text-success" : "text-danger"} style={{fontWeight: 'bold'}}>{row.status}</div>                    
                    }
                </div>
            ),
        },
        {
            name: 'Action',
            width:'21%',
            cell: row => (
                <div className="d-flex align-items-center">
                    {row.notes === null && (
                        <>
                            {/* Edit Button */}
                            <Tooltip title="Edit" size="small" className="icon icon-sm icon-secondary">
                                <a onClick={() => handleEdit(row.id)} data-bs-title="Edit">
                                    <div className="table-icon bg-success-subtle">
                                        <i className="fa-solid fa-pen-to-square text-success"></i>
                                    </div>
                                </a>
                            </Tooltip>
                            
                            {/* Delete Button */}
                            <Tooltip title="Delete" size="small" className="icon icon-sm icon-secondary">
                                <a className="mx-2" onClick={() => handleDelete(row.id)} data-bs-title="Delete">
                                    <div className="table-icon bg-danger-subtle">
                                        <i className="fa-solid fa-trash-can text-danger"></i>
                                    </div>
                                </a>
                            </Tooltip>
        
                            {/* Download Button */}
                            <Tooltip title="Download" size="small" className="icon icon-sm icon-secondary">
                                <a href={`${row.filename}`} download data-bs-title="Download" target="_Blank">
                                    <div className="table-icon bg-primary-subtle">
                                        <i className="fa-solid fa-download text-primary"></i>
                                    </div>
                                </a>
                            </Tooltip>
        
                            {/* Conditional Chips (Complete/Approve) */}
                            {row.status === 'In-Complete' && (
                                <Tooltip title="Click here to Complete" size="small" className="icon icon-sm icon-secondary">
                                    <Chip label="Complete" className="ss-badge ms-1" color="info" component="button" onClick={() => handleComplete(row.id, row.brandId)} />
                                </Tooltip>
                            )}
        
                            {row.status === 'Complete' && (
                                <Tooltip title="Click here to Approve" size="small" className="icon icon-sm icon-secondary">
                                    <Chip label="Approve" className="ss-badge ms-1" color="success" component="button" onClick={() => handleApprove(row.id)} />
                                </Tooltip>
                            )}
                        </>
                    )}
        
                    {/* Always show Delete option */}
                    {row.notes !== null && (
                        <>
                        <div style={{width:'25px'}}></div>
                        <Tooltip title="Delete" size="small" className="icon icon-sm icon-secondary">
                            <a className="mx-2" onClick={() => handleDelete(row.id)} data-bs-title="Delete">
                                <div className="table-icon bg-danger-subtle">
                                    <i className="fa-solid fa-trash-can text-danger"></i>
                                </div>
                            </a>
                        </Tooltip>
                        </>
                    )}
                </div>
            ),
        },        
    ];

    return(
        <>
            <Loader loading={loading} />
            <div>
            <Header/>
            <section className="main-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-9 mb-3">
                            <div className="heading">Data</div>
                        </div>
                        <div className="col-md-4 mb-3 d-none d-md-block text-md-end">
                            <button type="button" className="add-btn" onClick={handleUpload}>
                                <i className="fa-solid fa-upload me-2"></i>Upload</button>
                        </div>
                        <div className="col-md-3 col-3 d-md-none d-sm-block mb-3 text-end">
                            <a href="#" className="add-btn" onClick={handleUpload}>
                                <i className="fa-solid fa-upload"></i>
                            </a>
                        </div>
                    </div>
                    <div className="card border-0 mb-3">
                        <div className="card-body">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-lg-3 col-md-3 mb-3 mb-md-0 col-12">
                                    <Autocomplete
                                        id="brand-select"
                                        className="autoComplete-select"
                                        size="small"
                                        style={{ backgroundColor: 'transparent', border: '0' }}
                                        options={brands}
                                        getOptionLabel={(option) => option.name}
                                        value={brands.find(brand => brand.id === parseInt(brandId)) || null}
                                        onChange={(event, newValue) => handleBrandChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params} 
                                                label="Brand" 
                                                className="form-control autoComplete-select fontFamily" 
                                                placeholder="Select Brand" 
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        componentsProps={{
                                            paper: {
                                                sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                            },
                                            popper: {
                                                sx: {fontFamily: 'VisbyCF-Medium',  letterSpacing: '1px'},
                                            },
                                        }}
                                    /> 

                                </div>
                                <div className="col-lg-3 col-md-3 mb-3 mb-md-0 col-12">
                                    <Autocomplete
                                        id="month-select"
                                        className="autoComplete-select"
                                        size="small"
                                        style={{ backgroundColor: 'transparent', border: '0' }}
                                        options={months}
                                        getOptionLabel={(option) => option}
                                        value={month || null}
                                        onChange={(event, newValue) => handleMonthChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Month"
                                                className="form-control autoComplete-select fontFamily"
                                                placeholder="Select Month"
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        componentsProps={{
                                            paper: {
                                                sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                            },
                                            popper: {
                                                sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                            },
                                        }}
                                    />                                    
                                </div>
                                <div className="col-lg-3 col-md-2 mb-3 mb-md-0 col-12">
                                    <Autocomplete
                                        id="year-select"
                                        className="autoComplete-select"
                                        size="small"
                                        style={{ backgroundColor: 'transparent', border: '0' }}
                                        options={years}
                                        getOptionLabel={(option) => option}
                                        value={year || null}
                                        onChange={(event, newValue) => handleYearChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Year"
                                                className="form-control autoComplete-select fontFamily"
                                                placeholder="Select Year"
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        componentsProps={{
                                            paper: {
                                                sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                            },
                                            popper: {
                                                sx: {fontFamily: 'VisbyCF-Medium', letterSpacing: '1px'},
                                            },
                                        }}
                                    />                                    
                                </div>
                                <div className="col-lg-3 col-md-4">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 mb-3 mb-md-0 col-12 pe-md-0">
                                            <div className="d-grid">
                                                <button type="button" className="add-btn" onClick={handleFilter} ><i className="fa-solid fa-filter me-2"></i>Filter</button>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-md-0 col-12 text-md-end">
                                            <div className="d-grid">
                                                <button type="button" className="add-btn-outline d-xl-block d-none"  onClick={handleClearFilter}>Clear Filter</button>
                                                <button type="button" className="add-btn-outline d-lg-block d-xl-none"  onClick={handleClearFilter}>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="card border-0 mb-3">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <DataTableComp columns={columns} data={uploads} defaultSortAsc={false} defaultSortFieldId={1} onSort={handleSort} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <UploadExcel 
                show={showExcelForm} 
                setShow={setShowExcelForm} 
                handleClose={handleCloseExcel} 
                loading={loading} 
                setLoading={setLoading}
                getList={getList}
                showMapping={showMapping}
                setShowMapping={setShowMapping}
                setUploadBrandId={setUploadBrandId}
                setUploadEditId={setUploadEditId}
                brands={brands}
            />
            <Edit 
                show={showEdit} 
                setShow={setShowEdit} 
                handleClose={handleCloseEdit} 
                loading={loading} 
                setLoading={setLoading}
                getList={getList}
                setShowMapping={setShowMapping}
                setShowEditMapping={setShowEditMapping}
                editId={uploadEditId}
                setUploadBrandId={setUploadBrandId}
                brands={brands}
            />
            <Mapping 
                show={showMapping} 
                setShow={setShowMapping} 
                handleClose={handleCloseMapping} 
                loading={loading} 
                setLoading={setLoading}
                getList={getList}
                brands={brands}
                uploadId={uploadEditId}
                brandId={uploadBrandId}
            />
            <EditMapping 
                show={showEditMapping} 
                setShow={setShowEditMapping} 
                handleClose={handleCloseEditMapping} 
                loading={loading} 
                setLoading={setLoading}
                getList={getList}
                brands={brands}
                uploadId={uploadEditId}
                brandId={uploadBrandId}
            />
            </div>
        </>
    )
}
import { Link, useLocation } from "react-router-dom";

export default function Nav() {
    const location = useLocation();
    return(
        <div className="row mt-15">
            <div className="col-md-12 col-12">
                <ul className="nav nav-tabs ssNavTab  d-flex justify-content-md-center align-items-center" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to={`/performance`} className={location.pathname === "/performance"? "nav-link active" : "nav-link"}>Performance</Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/brandAttributes`} className={location.pathname === "/brandAttributes"? "nav-link active" : "nav-link"} >Attributes</Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/nps`} className={location.pathname === "/nps"? "nav-link active" : "nav-link"} >NPS</Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/timeline`} className={location.pathname === "/timeline"? "nav-link active" : "nav-link"} >Timeline</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}
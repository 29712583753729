import { useEffect, useRef, useState } from "react";

export default function MonthYearFilters({
    handleMonthAndYearChange, 
    month,
    setMonth
}) {
    
    const [selectedMonthValue, setSelectedMonthValue] = useState(month);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const SelectMonthOptions = [
        { label: "Latest month", value: "1" },
        { label: "3 months", value: "3" },
        { label: "6 months", value: "6" },
        { label: "12 months", value: "12" },
        { label: "2 years", value: "24" },
        { label: "3 years", value: "36" },
    ];

    const handleSelectMonthChange = (value) => {
        setSelectedMonthValue(value);
        setMonth(value);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false); // Close dropdown
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return(
        <div className={`custom-dropdown ${isOpen ? 'dropdown-border' : ''}`}ref={dropdownRef}>
            <div className="">
                <div className={`dropdown-header ${isOpen ? '' : 'border-bottom-left-right-radius'}`} onClick={() => setIsOpen(!isOpen)}>
                    {SelectMonthOptions.find(option => option.value === selectedMonthValue)?.label || "Select an option"}
                    <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
                </div>

                {isOpen && (
                    <ul className="dropdown-list">
                        {SelectMonthOptions.map((option) => (
                            <li
                                key={option.value}
                                className={`dropdown-item-select ${selectedMonthValue === option.value ? 'selected' : ''}`}
                                onClick={() => handleSelectMonthChange(option.value)}
                            >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}
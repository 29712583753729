import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function BrandMetricsChart({ chartdata }) {
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);

    let isSelfDataValid = chartdata && chartdata.selfReportData && chartdata.selfReportData.length > 0;
    let isCompetitorDataValid = chartdata && chartdata.competitorReportData && chartdata.competitorReportData.length > 0;

    if(user?.brand?.hasAllAccess === '0' && isSelfDataValid){
        chartdata.selfReportData = chartdata.selfReportData.filter(
            item => item.type !== 'Preference' && item.type !== 'Preferred alternative' && item.type !== 'Main bank for borrowing'
        );
    }
    if(user?.brand?.hasAllAccess === '0' && isCompetitorDataValid){
        chartdata.competitorReportData = chartdata.competitorReportData.filter(
            item => item.type !== 'Preference' && item.type !== 'Preferred alternative' && item.type !== 'Main bank for borrowing'
        );
    }

    if (!isSelfDataValid && !isCompetitorDataValid) {
        return <div className="App" style={{ height: 'auto', width: "100%", color: 'white' }}>
            <p className="text-black ms-4">No data available!</p>
        </div>;
    }

    const formatValue = (value) => {
        if (value && (typeof value === 'number' || typeof value === 'string') && !isNaN(value)) {
            return parseFloat(Number(value).toFixed());
        }
        return 0;
    };

    const desiredLabelsOrder = ["Unprompted Awareness", "Prompted Awareness", "Consideration", "Investigation", "Main bank for borrowing", "Preference", "Preferred alternative"];
   
    const desiredLabelsQuestions = {
        "Unprompted Awareness": "When thinking about businesses that provide banking and financial services, which providers or brands first come to mind/are you aware of?",
        "Prompted Awareness": "From the list below, please select all the banking and financial services providers or brands you have heard of before today, even if you listed them earlier:",
        "Consideration": "Which of the following banking or financial service providers would you consider using in the future?",
        "Investigation": "Before today, which of the following banking or financial service providers have you looked into or researched?",
        "Main bank for borrowing": "And which banking and financial service provider do you use currently for the majority of your agribusiness borrowing?",
        "Preference": "Which of the following is your preferred bank?",
        "Preferred alternative": "And if you had to change providers for your agribusiness borrowing, which provider is the most appealing to you?",
    };

    const splitTextIntoChunks = (text, chunkSize) => {
        const words = text ? text.split(' ') : '';
        const chunks = [];

        for (let i = 0; i < words.length; i += chunkSize) {
            chunks.push(words.slice(i, i + chunkSize).join(' '));
        }

        return chunks;
    };

    const formatLabel = (label) => {
        if (!label) return label;

        const words = label.split(" ");
        if (label === "Main bank for borrowing") {
            return ["Main bank for", "borrowing"];
        }
        if (words.length === 2) {
            return words;
        }
        if (words.length > 3) {
            return [words.slice(0, 3).join(" "), words.slice(3).join(" ")];
        }
        return [label];
    };
    
    const labels = chartdata && chartdata.selfReportData
        ? chartdata.selfReportData
            .map(item => formatLabel(item.type)) 
            .sort((a, b) => desiredLabelsOrder.indexOf(a.join(" ")) - desiredLabelsOrder.indexOf(b.join(" ")))
        : [];

    const getDataForLabels = (dataArray, labels) => {
        return labels.map(label => {
            const formattedLabel = Array.isArray(label) ? label.join(" ") : label;
            const foundItem = dataArray.find(item => item.type === formattedLabel);
            return foundItem ? formatValue(foundItem.value) : 0;
        });
    };

    const separatorLinePlugin = {
        id: 'separatorLine',
        beforeDraw: (chart) => {
            const { ctx, scales } = chart;
            const meta0 = chart.getDatasetMeta(0); // First bar (Dataset 1 - Orange bars)
            const meta1 = chart.getDatasetMeta(1); // Second bar (Dataset 2 - Grey bars)

            ctx.save();
            ctx.setLineDash([1, 1.5]); // Dotted line configuration
            ctx.strokeStyle = 'grey';
            ctx.lineWidth = 1.5;

            for (let i = 0; i < meta0.data.length; i++) {
                const barHeight = meta0.data[i].height;

                // Get the Y coordinates of the two bars for each label
                const y0 = meta0.data[i].y; // Y coordinate of the first bar
                const y1 = meta1.data[i].y; // Y coordinate of the second bar
                const separatorY = (y0 + y1) / 2; // Midpoint between the two bars

                // Draw the separator line for each pair of bars
                ctx.beginPath();
                ctx.moveTo(scales.x.left, separatorY);
                ctx.lineTo(scales.x.right, separatorY);
                ctx.stroke();
            }

            ctx.restore();
        }
    };

    const data = {
        labels: labels,
        datasets: [
            {
                label: isSelfDataValid ? chartdata.selfReportData[0].brand_name : 'Dataset 1',
                data: isSelfDataValid ? getDataForLabels(chartdata.selfReportData, labels) : labels.map(() => 0),
                backgroundColor: '#f06b22',
                borderColor: '#f06b22',
                borderWidth: 1,
                borderRadius: 10, //[10, 10, 10, 10, 10, 10],
                barPercentage: 0.7,
                categoryPercentage: 1.1, //0.99,
                borderSkipped: false,
                datalabels: {
                    color: (context) => {
                        const value = context.dataset.data[context.dataIndex];
                        return value === 0 ? '#313932' : '#fff'; 
                    },
                    font: {
                        size: window.innerWidth < 767 ? 10 : 12,
                        family: 'Graphik-Semibold, sans-serif',
                    },
                },
            },
            {
                label: isCompetitorDataValid ? chartdata.competitorReportData[0].comp_name : 'Dataset 2',
                data: isCompetitorDataValid ? getDataForLabels(chartdata.competitorReportData, labels) : labels.map(() => 0),
                backgroundColor: '#dae8d8', // Light blue
                borderColor: '#dae8d8',
                borderWidth: 1,
                borderRadius: 10, //[10, 10, 10, 10, 10, 10],
                borderSkipped: false,
                barPercentage: 0.69,
                categoryPercentage: 0.98,
                datalabels: {
                    color: 'rgb(49, 57, 50)',
                    font: {
                        size: window.innerWidth < 767 ? 8 : 10,
                        family: 'Graphik-Regular, sans-serif',
                        // letterSpacing: '1.5px',
                    },
                }
            }
        ]
    };

    const options = {
        indexAxis: 'y', // Horizontal bar chart
        responsive: true,
        maintainAspectRatio: window.innerWidth < 767 ? false : true,
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: '#424242',
                    display: false // Hide grid lines
                },
                ticks: {
                    display: false,
                    color: '#ffffff', // X-axis text color
                    font: {
                        family: 'Graphik-Light, sans-serif',
                        size: window.innerWidth > 767 ? 8 : 10,
                        // letterSpacing: 1.5,
                    }
                },
                border: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: '#424242',
                    display: false, // Hide grid lines
                },
                ticks: {
                    font: (context) => {
                        const width = context.chart.width;
                        return {
                            size: window.innerWidth < 1100 ? 10 : 15, // Responsive font size for Y-axis
                            family: 'Graphik-Light, sans-serif',
                            weight: '500',
                            color: 'rgb(49, 57, 50)',
                        };
                    },
                    // align: window.innerWidth < 767 ? 'start' : 'start', // Align left for mobile, center for desktop
                    align: 'center', 
                    padding: window.innerWidth < 767 ? 10 : 10,
                    crossAlign: 'far', //align the labels to left 
                },
                border: {
                    display: false,
                },
                categoryPercentage: 0.6,
                barPercentage: 0.8,
            }
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: '#ffffff',
                    font: {
                        family: 'Graphik-Light, sans-serif',
                    }
                }
            },
            datalabels: {
                font: (context) => {
                    const width = context.chart.width;
                    return {
                        size: window.innerWidth < 1100 ? 10 : 15, // Responsive font size for Y-axis
                        family: 'Graphik-Light, sans-serif',
                        color: '#313932'
                    };
                },
                color: '#ffffff',
                formatter: (value) => value.toFixed() + '%',
                align: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return (value <= 1 && value >= 0) ? 'end' : 'start';
                },
                anchor: (context) => {
                    const value = context.dataset.data[context.dataIndex];
                    return (value <= 1 && value >= 0) ? 'end' : 'end';
                },
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItem) {
                        const additionalInfo = desiredLabelsQuestions[tooltipItem[0].label];
                        const textChunks = splitTextIntoChunks(additionalInfo, 10);
                        return [(tooltipItem[0].label).replace(/,/g, ' '), ...textChunks];
                    },
                    label: function (tooltipItem) {
                        const label = tooltipItem.dataset.label;
                        return [tooltipItem.dataset.label + ': ' + tooltipItem.raw] + '%';
                    }
                },
                titleFont: {
                    family: 'Graphik-Light, sans-serif',
                    // letterSpacing: 1.5,
                    size: 12,
                },
                bodyFont: {
                    family: 'Graphik-Light, sans-serif',
                    // letterSpacing: 1.5,
                    size: 10
                },
                footerFont: {
                    family: 'Graphik-Light, sans-serif',
                    // letterSpacing: 1.5,
                    size: 10
                }
            }
        },
        layout: {
            padding: {
                // top: 12, //'5%',
                // top: 20, //'5%',
                top: 0, //'5%',
                right: 20, //'5%',
                bottom: 20, //'5%',
                // left: 20, //'5%'
                left: 12,
            }
        }
    };

    return (
        <div className="App metricChart" style={{ height: 'auto', width: "100%" }}>
            <Bar data={data} options={options} plugins={[separatorLinePlugin]} />
        </div>
    );
}
import * as Yup from 'yup';
import { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Header/Header";

export default function  ChangePassword(){
    const [values, setValues] = useState({
        password: '',
        currentPassword: '',
        showNewPassword: false,
        confirmNewPassword: '',
        showCurrentPassword: false,
        showConfirmNewPassword: false
    })

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Current Password is required')
            .test('is-valid', 'Current Password is wrong!', value => handleCheckCurrentPassword(value)),
        password: Yup.string()
            // .min(8, 'New Password must be at least 8 characters')
            // .required('New Password is required'),
            .min(8, 'New Password must be at least 8 characters')
            .matches(/[a-z]/, 'New Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'New Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'New Password must contain at least one number')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'New Password must contain at least one special character')
            .required('New Password is required'),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm New Password is required'),
    });

    // const [changePasswordStatus, setChangePasswordStatus] = useState('');
    // const [changePasswordMsg, setChangePasswordMsg] = useState('');
    
    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmNewPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
            formik.resetForm(); 
        }
    });

    const handleSubmit = async (values) => {
        try {
            let res = await axios.post('/api/users/changepassword', values);
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success')
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to update password', 'error');
        }
    }

    const handleClickShowCurrentPassword = () => {
        setValues({ ...values, showCurrentPassword: !values.showCurrentPassword })
    }

    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword })
    }

    const handleClickShowConfirmNewPassword = () => {
        setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword })
    }
    
    const handleCheckCurrentPassword = async (password) => {
        let data = {}
        let userData = localStorage.getItem('ag-authUser');
        userData = JSON.parse(userData);
        if(password){
            data.id = userData.id;
            data.password = password;
            let response = await axios.post(`/api/users/checkvalidatepassword`, data)
            let result = response.data;
            if(result.success && result.exists === true){
                return true
            }
            else {
                return false
            }
        }
        else{
            return false
        }
    }

    return(
        <>
            <Header />  
                <section className="main-content">
                <div className="container">
                    <div className="row" style={{alignItems: 'center',justifyContent: 'center'}}>
                        <div className="col-md-12">
                            <ul className="nav nav-pills ss-nav-pills flex-sm-column flex-md-row mb-3">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/profile"><i className="fa fa-user me-1"></i> Account</Link>
                                </li>
                                <li className="nav-item">   
                                    <Link className="nav-link active" to="#;"><i className="fa fa-key bx bx bxs-key me-1"></i> Change Password</Link>
                                </li>
                            </ul>
                            <div className="card mb-4">
                                <h5 className="card-header">Change password</h5>
                                {/* Account */}
                                <hr className="my-0" />
                                <div className="card-body">
                                    <form id="formAccountSettings" method="POST" onSubmit={formik.handleSubmit} >
                                        <div className="row">
                                            <div className="mb-3 col-md-6 form-password-toggle">
                                                <div className="d-flex justify-content-between">
                                                    <label className="form-label" htmlFor="password">Old Password</label>
                                                </div>
                                                <div className="input-group input-group-merge">
                                                    <input type={values.showCurrentPassword ? "text" : "password"} id="oldPassword" className="form-control border-right" name="oldPassword" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" value={formik.values.oldPassword} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    <span className="input-group-text cursor-pointer" onClick={handleClickShowCurrentPassword}><i className={values.showCurrentPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>
                                                </div>

                                                {formik.touched.oldPassword && formik.errors.oldPassword && (
                                                    <div className="text-danger errorMSG">{formik.errors.oldPassword}</div>
                                                )}
                                            </div>
            
                                            <div className="mb-3 col-md-6 form-password-toggle">
                                                <div className="d-flex justify-content-between">
                                                    <label className="form-label" htmlFor="password">New Password</label>
                                                </div>
                                                <div className="input-group input-group-merge">
                                                    <input type={values.showNewPassword ? 'text' : 'password'} id="password" className="form-control border-right" name="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    <span className="input-group-text cursor-pointer" onClick={handleClickShowNewPassword}><i className={values.showNewPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>
                                                </div>

                                                {formik.touched.password && formik.errors.password && (
                                                    <div className="text-danger errorMSG">{formik.errors.password}</div>
                                                )}
                                            </div>
            
                                            <div className="mb-3 form-password-toggle">
                                                <div className="d-flex justify-content-between">
                                                    <label className="form-label" htmlFor="password">Re Enter New Password</label>
                                                </div>
                                                <div className="input-group input-group-merge">
                                                    <input type={values.showConfirmNewPassword ? 'text' : 'password'} id="confirmNewPassword" className="form-control border-right" name="confirmNewPassword" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" value={formik.values.confirmNewPassword} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    <span className="input-group-text cursor-pointer" onClick={handleClickShowConfirmNewPassword}><i className={values.showConfirmNewPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>
                                                </div>

                                                {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                                                    <div className="text-danger errorMSG">{formik.errors.confirmNewPassword}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button type="submit" className="save-btn">Save changes</button>
                                            {/* <button type="reset" className="btn btn-outline-secondary">Cancel</button> */}
                                        </div>
                                    </form>
                                </div>
                                {/* /Account */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>  
        </>
    )
}